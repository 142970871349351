import React, {useState} from 'react'
import './cta.css'

export default function Cta({mediaType}) {
    let label = ''
    const btnClass = mediaType ? mediaType.toLowerCase() : ''

    switch(mediaType){
        case 'Video': 
            label = 'Watch the video'
            break
        default:
            label = 'Read the story'
    }

    return (
        <div className='ctacomponent'>
            <span className={`cta ${btnClass}btn`}>{label}</span>
        </div>
    )

}