import React, { useState, useEffect, useRef } from 'react'
import './search.css'
import api from '../../services/api'
import LinksBoxItem from '../linksBox/linksBoxItem'
import Select, { components } from 'react-select';
import selectArrow from './images/selectArrow.svg'

export default function Search({searchIsOpen, handleSearch, links}) {
    const apiURL = process.env.REACT_APP_API_URL

    const [verticals, setVerticals] = useState([])
    const [selectedVertical, setSelectedVertical] = useState({})
    const solutionsSelect = useRef()
    const [solutions, setSolutions] = useState([])
    const [selectedSolution, setSelectedSolution] = useState({})
    const customersSelect = useRef()
    const [customers, setCustomers] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState({})
    const [assets, setAssets] = useState([])
    const searchRef = useRef(null)
    const searchHeader = useRef(null)
    const qs = require('qs')

    // Vertical and Insutry are the same thing
    const getVerticals = async () => {
        try{
            const query = qs.stringify({
                populate: '*',
                sort: ['Name'],
                pagination: {
                    pageSize: 100
                }
            }, {
                encodeValuesOnly: true,
            })

            const response = await api.get(`/verticals?${query}`)
            if(response.data){
                const verticalsData = response.data.data
                let verticalsArr = [{ value: '', label: 'Select vertical' }]

                for(let vertical of verticalsData){
                    const verticalFilter = {industries: {
                        id: {
                            $eq: vertical.id,
                        },
                    },}

                    const total = await getAssets(verticalFilter, selectedSolution, selectedCustomer)
                    if(total.meta.pagination.total > 0) verticalsArr = [...verticalsArr, { value: vertical.id, label: vertical.attributes.Name }]
                }

                setVerticals(verticalsArr)
            }
        } catch(err) {
            console.log(err)
        }
    }

    const getSolutions = async () => {
        try{
            const query = qs.stringify({
                populate: '*',
                sort: ['Name'],
                pagination: {
                    pageSize: 100
                }
            }, {
                encodeValuesOnly: true,
            })

            const response = await api.get(`/solutions?${query}`)
            console.log("🚀 ~ file: index.js:64 ~ getSolutions ~ `/solutions?${query}`:", `/solutions?${query}`)
            if(response.data){
                
                const solutionsData = response.data.data
                let solutionsArr = [{ value: '', label: 'Select solution' }]

                for(let solution of solutionsData){
                    const solutionFilter = {solution: {
                        id: {
                            $eq: solution.id,
                        },
                    },}

                    const total = await getAssets(selectedVertical, solutionFilter, selectedCustomer)
                    if(total.meta.pagination.total > 0) solutionsArr = [...solutionsArr, { value: solution.id, label: solution.attributes.Name }]
                }

                setSolutions(solutionsArr)
            }
        } catch(err) {
            console.log(err)
        }
    }

    const getCustomers = async () => {
        try{
            const query = qs.stringify({
                populate: '*',
                sort: ['Name'],
                pagination: {
                    pageSize: 100
                }
            }, {
                encodeValuesOnly: true,
            })

            const response = await api.get(`/customers?${query}`)
            if(response.data){
                const customersData = response.data.data
                let customersArr = [{ value: '', label: 'Select customer' }]

                for(let customer of customersData){
                    const customerFilter = {customer: {
                        id: {
                            $eq: customer.id,
                        },
                    },}

                    const total = await getAssets(selectedVertical, selectedSolution, customerFilter)
                    if(total.meta.pagination.total > 0) customersArr = [...customersArr, { value: customer.id, label: customer.attributes.Name }]
                }

                setCustomers(customersArr)
                console.log("🚀 ~ file: index.js:126 ~ getCustomers ~ customersArr:", customersArr)
            }
        } catch(err) {
            console.log(err)
        }
    }

    const getAssets = async (vertical = selectedVertical, solution = selectedSolution, customer = selectedCustomer) => {
        try{
            const query = qs.stringify({
                populate: [
                    "Image",
                    "Video",
                    "PDF",
                    "solution",
                    "customer",
                    "Subtitles",
                    "Subtitles.VTT",
                ],
                filters: {
                    $and: [
                        vertical,
                        solution,
                        customer,
                    ],
                },
                sort: ['Order'],
                pagination: {
                    pageSize: 300
                }
            }, {
                encodeValuesOnly: true,
            })

            const response = await api.get(`/assets?${query}`)
            return response.data
        } catch(err) {
            console.log(err)
        }
    }

    const handleVerticals = (e) => {
        if(e.value !== ""){
           setSelectedVertical({
                    industries: {
                        id: {
                            $eq: e.value,
                        },
                    },
            })
        }
        else setSelectedVertical({})
        setSolutions([])
        setSelectedSolution({})
        setCustomers([])
        setSelectedCustomer({})
        solutionsSelect.current.setValue("")
        customersSelect.current.setValue("")
    }

    const handleSolutions = (e) => {
        if(e.value !== ""){
           setSelectedSolution({
                    solution: {
                        id: {
                            $eq: e.value,
                        },
                    },
            })
        }
        else setSelectedSolution({})
        setCustomers([])
        setSelectedCustomer({})
        customersSelect.current.setValue("")
    }

    const handleCustomers = (e) => {
        if(e.value !== ""){
           setSelectedCustomer({
                    customer: {
                        id: {
                            $eq: e.value,
                        },
                    },
            })
        }
        else setSelectedCustomer({})
    }

    useEffect(async () => {
        const assetsResults = await getAssets()
        setAssets(assetsResults.data)
    }, [selectedVertical, selectedSolution, selectedCustomer])

    useEffect(async () => {
        if(Object.keys(selectedVertical).length != 0) getSolutions()
        if(Object.keys(selectedSolution).length != 0) getCustomers()
    }, [assets])

    useEffect(async () => {
        getVerticals()
        getSolutions()
        getCustomers()
        const assetsResults = await getAssets()
        setAssets(assetsResults.data)

        const handleEscapeKey = (e) => {
            if (e.key === 'Escape') {
                handleSearch(false)
            }
        }
        document.addEventListener('keydown', handleEscapeKey)
        return () => document.removeEventListener('keydown', handleEscapeKey)
    }, [])

    const customStyles = {
        menu: (styles) => ({
            ...styles,
            background: '#01A982',
            width: '90%',
            marginLeft: 7
        }),
        option: (styles, state) => ({
          ...styles,
          color: state.isSelected ? '#000' : '#fff',
          padding: 5,
          paddingLeft: 14,
          background: state.isSelected ? '#6DE7A6' : '#01A982',
          fontFamily: "MetricHPE-Semibold",
          ':active': {
            backgroundColor: '#6DE7A6'
          },
        }),
        dropdownIndicator: () => ({
            color: '#fff',
            padding: 5,
            paddingRight: 10
        }),
        control: () => ({
          background: '#01A982',
          color: '#fff',
          minWidth: 175,
          borderRadius: 18,
          display: 'flex',
          fontFamily: "MetricHPE-Semibold",
          paddingLeft: 12
        }),
        singleValue: (styles) => ({
          ...styles,
          color: '#fff',
          fontFamily: 'MetricHPE-Bold'
        }),
        placeholder: (styles) => ({
          ...styles,
          color: '#fff',
          fontFamily: 'MetricHPE-Bold'
        })
      }

      const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <img src={selectArrow} />
          </components.DropdownIndicator>
        );
      };

      function useOnClickOutside(ref, handler) {
        useEffect(
          () => {
            const listener = (event) => {
              if (!ref.current || ref.current.contains(event.target)) {
                return;
              }
              handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
              document.removeEventListener("mousedown", listener);
              document.removeEventListener("touchstart", listener);
            };
          },
          [ref, handler]
        );
      }

    //useOnClickOutside(searchRef, () => handleSearch(false))

    return (
        searchIsOpen && (
            <div className="search">
                <header className='search-header'>
                    <button className="search__back" onClick={() => handleSearch(false)}>Back</button>
                    <div className='form-container'>
                        <h2>Search</h2>
                        <form className='filters'>
                            <span>Filter By:</span>
                                <Select
                                    placeholder="Select industry"
                                    onChange={handleVerticals}
                                    options={verticals}
                                    styles={customStyles}
                                    components={{
                                        IndicatorSeparator: () => null,
                                        DropdownIndicator
                                    }}
                                />
                            
                                <Select
                                    ref={solutionsSelect}
                                    placeholder="Select solution"
                                    onChange={handleSolutions}
                                    options={solutions}
                                    styles={customStyles}
                                    components={{
                                        IndicatorSeparator: () => null,
                                        DropdownIndicator
                                    }}
                                />

                                <Select
                                    ref={customersSelect}
                                    placeholder="Select Customer"
                                    onChange={handleCustomers}
                                    options={customers}
                                    styles={customStyles}
                                    clearable={false}
                                    components={{
                                        IndicatorSeparator: () => null,
                                        DropdownIndicator
                                    }}
                                />
                        </form>
                    </div>
                </header>
                <div ref={searchRef} className="search__container">
                    <div className="searchlinksBox__container">
                        {assets?.map((asset) => {
                            const link = asset.attributes
                            const imageURL = link.Image.data ? apiURL + link.Image.data.attributes.url : ''
                            const videoURL = link.Video.data ? apiURL + link.Video.data.attributes.url : ''
                            const pdfURL = link.PDF.data ? apiURL + link.PDF.data.attributes.url : ''
                            const QRCodeURL = link.QRCodeURL ? link.QRCodeURL : ''
                            const mediaType = link.MediaType ? link.MediaType : ''

                            return  <LinksBoxItem 
                                        key={asset.id.toString()}
                                        url={link.URL}
                                        image={imageURL}
                                        category={link.Category}
                                        title={link.Header}
                                        subheader={link.Subheader}
                                        video={videoURL}
                                        subtitles={link.Subtitles}
                                        pdf={pdfURL}
                                        QRCodeURL={QRCodeURL}
                                        mediaType={mediaType}
                                    />
                        })}
                    </div>
                </div>
            </div>
        )
    )
}