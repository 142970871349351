import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

export default function Content({content, containerClass}) {
    const uploadsDir = "/uploads/"
    const apiURL = process.env.REACT_APP_API_URL

    return (
        <ReactMarkdown 
            children={content && content.replace(uploadsDir, apiURL + uploadsDir)} 
            className={containerClass} 
            rehypePlugins={[rehypeRaw]}
        />
    )
}