import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './need.css'
import api from '../../services/api'
import Content from '../../components/content'
import Header from '../../components/header'
import ScrollMore from '../../components/scrollMore'

export default function Needs() {
    const { verticalId, id } = useParams()
    const apiURL = process.env.REACT_APP_API_URL
    const [needId, setNeedId] = useState(id)
    const [need, setNeed] = useState()
    const [verticals, setVerticals] = useState()
    const [fadeContainer, setFadeContainer] = useState(true)

    const getContent = async () => {
        try{
            const qs = require('qs')
            const query = qs.stringify({
                populate: {
                    populate: '*',
                    HeroImage: '*',
                    Icon: '*',
                    Solutions: {
                        populate: '*',
                    },
                },
                }, {
                encodeValuesOnly: true,
            })

            const response = await api.get(`/needs/${needId}/?${query}`)
            if(response.data){
                setNeed(response.data.data)
                setFadeContainer(true)
            }
        } catch(err) {
            console.log(err)
        }
    }
    const getVerticals = async () => {
        try{
            const qs = require('qs')
            const queryIndustry = qs.stringify({
                populate: {
                    populate: '*',
                    Needs: {
                        populate: '*',
                    },
                },
                }, {
                encodeValuesOnly: true,
            })

            const responseIndustry = await api.get(`/verticals/${verticalId}/?${queryIndustry}`)
            
            if(responseIndustry.data){
                setVerticals(responseIndustry.data.data)
            }
        } catch(err) {
            console.log(err)
        }
    }

    let navigate = useNavigate()
    const handleMenu = (needId) => {
        setFadeContainer(false)
        navigate(`/needs/${verticalId}/${needId}`)
        setNeedId(needId)
        window.scrollTo(0, 0)
    }

    const handleSolution = (solutionId) => {
        navigate(`/solution/${verticalId}/${needId}/${solutionId}`)
    }

    useEffect(() => {
        getContent()
        getVerticals()
    }, [])

    useEffect(() => {
        getContent()
    }, [needId])

    return (
        <div className='pillar need'>
            
            <section className={`container ${fadeContainer ? 'fade-in' : 'fade-out'}`}>
                <Header backURL={`/vertical/${verticalId}`} />
                
                <div className="content">
                    <div>
                        <h3 className='title'>{need?.attributes.Title}</h3>
                        <div className="hero mobile">
                            { need?.attributes.HeroImage.data && (<img src={apiURL + need?.attributes.HeroImage.data.attributes.url} alt={need?.attributes.Title} />)}
                        </div>
                        <Content content={need?.attributes.Content} containerClass="content-container" />    
                    </div>
                    <div className="hero d">
                        { need?.attributes.HeroImage.data && (<img src={apiURL + need?.attributes.HeroImage.data.attributes.url} alt={need?.attributes.Title} />)}
                    </div>
                </div>
                <ul className="links">
                    {need?.attributes.Solutions.data.map((caseS, key) => (
                        <li key={key} onClick={() => handleSolution(caseS.id)}>
                            <button style={{ 
                                backgroundImage: `url("${apiURL}${caseS.attributes.Icon.data?.attributes.url}")` 
                            }}>
                                {caseS.attributes.Title}
                            </button>
                        </li>
                    ))}
                </ul>
            </section>
            <section className="learnmore">
                <h4>Learn more about modernizing {verticals?.attributes.Name.toLowerCase()}</h4>
                <ul className="menu">
                    {verticals?.attributes.Needs.data.map(need => (
                        <li key={need.id} className={need.attributes.NeedsType.toLowerCase()} onClick={() => handleMenu(need.id)}>
                                <span>{need.attributes.NeedsType}</span>
                                <h2>{need.attributes.Title}</h2>
                                <div className="arrowButton"></div>
                        </li>
                    ))}
                </ul>
            </section>
            <ScrollMore />
        </div>       
    )
}