import './App.css'
import { BrowserRouter as Router, Routes, Route, useSearchParams, useLocation } from 'react-router-dom'

import Origin from './components/origin'
import Home from './pages/home'
import Verticals from './pages/verticals'
import Vertical from './pages/vertical'
import Needs from './pages/needs'
import Solution from './pages/solution'

function App() {
  return (
    <Router>
      <Routes>
          <Route path="/" element={<Origin><Home /></Origin>} />
          <Route path="/verticals" element={<Origin><Verticals /></Origin>} />
          <Route path="/vertical/:id" element={<Origin><Vertical /></Origin>} />
          <Route path="/needs/:verticalId/:id" element={<Origin><Needs /></Origin>} />
          <Route path="/solution/:verticalId/:needsId/:solutionId" element={<Origin><Solution /></Origin>} />
      </Routes>
    </Router>
  );
}

export default App;
