import React, {useState} from 'react'
import VideoPlayer from '../../components/videoPlayer'
import Pdf from '../../components/pdf'
import './heroMedia.css'


export default function HeroMedia({apiURL, media}) {
    const mediaType = media.attributes.MediaType
    const [videoIsOpen, setVideoIsOpen] = useState(false)
    const [pdfIsOpen, setpdfIsOpen] = useState(false)

    const handleHeroClick = () => {
        const video = media.attributes.Video.data ? media.attributes.Video.data.attributes.url : ''
        const pdf = media.attributes.PDF.data ? media.attributes.PDF.data.attributes.url : ''
        const url = media.attributes.URL ? media.attributes.URL : ''


        if(video){
            setVideoIsOpen(true)
        } else if(pdf){
            setpdfIsOpen(true)
        } else if(url) {
            window.open(url)
        }
    }

    const handleVideoPlayer = (isOpen) => {
        setVideoIsOpen(isOpen)
    }

    const handlePdfViewer = (isOpen) => {
        setpdfIsOpen(isOpen)
    }

    return (
        <div className='heroMedia' onClick={handleHeroClick}>
            <div className='thumb_container' 
                style={{ 
                    backgroundImage: `url("${apiURL}${media.attributes.Image.data?.attributes.url}")` 
                }}
            >
                
                {mediaType == 'Video' && <img src="/play.svg" className='play' />}
            </div>
            {videoIsOpen && (
                <VideoPlayer 
                    url={apiURL + media.attributes.Video.data.attributes.url} 
                    QRCodeURL={media.attributes.QRCodeURL}
                    title={media.attributes.Header}
                    subheader={media.attributes.Subheader}
                    subtitles={media.attributes.Subtitles}
                    handleVideoPlayer={handleVideoPlayer}
                />
            )}
            {pdfIsOpen && (
                <Pdf 
                    url={apiURL + media.attributes.PDF.data.attributes.url} 
                    QRCodeURL={media.attributes.QRCodeURL}
                    title={media.attributes.Header}
                    handlePdfViewer={handlePdfViewer}
                />
            )}
        </div>
    )
}