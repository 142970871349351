import React, {useState, useEffect, useRef} from 'react'
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack'
import QRCode from 'react-qr-code'
import './pdf.css'

export default function PdfViewer({url, QRCodeURL, title, handlePdfViewer}) {
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [initialHeight, setInitialHeight] = useState(null)
    const pdfWrapper = useRef(null)
    const pdfRef = useRef(null)
    const pdfDocumentRef = useRef(null)

    const setPdfSize = () => {
        if (pdfWrapper && pdfWrapper.current) {
            setInitialHeight(pdfWrapper.current.getBoundingClientRect().height - 230)
        }
    }
    
    useEffect(() => {
        window.addEventListener('resize', setPdfSize)
        setPdfSize();
        return () => {
            window.removeEventListener('resize', setPdfSize)
        }
    }, [])

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const updatePageNumber = (page) => {
        setPageNumber(page)
    }

    const useOnClickOutside = (ref, handler) => {
        useEffect(
          () => {
            const listener = (event) => {
              if (!ref.current || ref.current.contains(event.target)) {
                return
              }
              handler(event);
            };
            document.addEventListener("mousedown", listener)
            document.addEventListener("touchstart", listener)
            return () => {
              document.removeEventListener("mousedown", listener)
              document.removeEventListener("touchstart", listener)
            };
          },
          [ref, handler]
        );
      }

    useOnClickOutside(pdfRef, () => handlePdfViewer(false))

    return (
        <div className='pdf'>
            <header className='pdf__header'>
                <button className="pdf__back" onClick={() => handlePdfViewer(false)}>Back</button>
                <div className='pdf__titleBox'>
                    {/*title && (
                        <h3 className='pdf__header'>{title}</h3>
                    )*/}
                    {QRCodeURL && (
                        <>
                        <h3 className='pdf__header'>Get the PDF</h3>
                        <div className='qrcode'>
                            <QRCode 
                                size={76}
                                value={QRCodeURL}
                            />
                        </div>
                        </>
                    )}
                </div>
            </header>
            <div className="pdf__container" ref={pdfWrapper}>
                <div ref={pdfRef} className='clickContainer'>
                    <div className='gradientContainer'>

                        <iframe src={`${url}#view=FitH`} width="100%" className='iframe'></iframe>

                         {/**
                        <Document
                            ref={pdfDocumentRef}
                            file={url}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onClick={() => { if(pageNumber !== numPages) updatePageNumber(pageNumber + 1)}}
                        >
                            <Page pageNumber={pageNumber}  height={initialHeight} />
                        </Document>
                          */}
                    
                    </div>
                    {/**
                    <div className='pdf__disclaimer'>
                        <div className="page-controls">
                            <button type="button" disabled={pageNumber === 1} onClick={() => updatePageNumber(pageNumber - 1)}>‹ prev</button>
                            <span>{pageNumber}/{numPages}</span>
                            <button type="button" disabled={pageNumber === numPages} onClick={() => updatePageNumber(pageNumber + 1)}>next ›</button>
                        </div>
                    </div>
                     */}
                </div>
            </div>
        </div>
    )
}