import React, {useEffect, useRef} from 'react'
import './linksBox.css'
import LinksBoxItem from './linksBoxItem'

export default function LinksBox({linksBoxIsOpen, handleLinksBox, links}) {
    const apiURL = process.env.REACT_APP_API_URL
    const linksRef = useRef(null)

    function useOnClickOutside(ref, handler) {
        useEffect(
          () => {
            const listener = (event) => {
              if (!ref.current || ref.current.contains(event.target)) {
                return;
              }
              handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
              document.removeEventListener("mousedown", listener);
              document.removeEventListener("touchstart", listener);
            };
          },
          [ref, handler]
        );
      }

    useOnClickOutside(linksRef, () => handleLinksBox(false))

    return (
        linksBoxIsOpen && (
            <div className="linksBox">
                <button className="linksBox__close" onClick={() => handleLinksBox(false)}>X</button>
                <div ref={linksRef} className="linksBox__container">
                    {links.map((link) => {
                        const imageURL = link.attributes.Image.data ? apiURL + link.attributes.Image.data.attributes.url : ''
                        const videoURL = link.attributes.Video.data ? apiURL + link.attributes.Video.data.attributes.url : ''
                        const pdfURL = link.attributes.PDF.data ? apiURL + link.attributes.PDF.data.attributes.url : ''

                        return  <LinksBoxItem 
                                    key={link.id}
                                    url={link.attributes.URL}
                                    image={imageURL}
                                    type={link.attributes.Type}
                                    title={link.attributes.Header}
                                    subheader={link.attributes.Subheader}
                                    video={videoURL}
                                    subtitles={link.attributes.Subtitles}
                                    pdf={pdfURL}
                                />
                    })}
                </div>
            </div>
        )
    )
}