import React, { useState, useEffect } from 'react'
import './home.css'
import { Unity, useUnityContext } from "react-unity-webgl"
import { useNavigate } from 'react-router-dom'
import PopUp from '../../components/popup'
import Search from '../../components/search'
import api from '../../services/api'
import Content from '../../components/content'

import LinksBox from '../../components/linksBox'
import Network from '../../components/network'

export default function Home() {
    const [pageId, setPageId] = useState(1)
    const [pageInfo, setPageInfo] = useState(null)
    const [popUpInfo, setPopUpInfo] = useState({})
    const [popUpIsOpen, setPopUpIsOpen] = useState(false)
    const [linksBoxIsOpen, setLinksBoxIsOpen] = useState(false)
    const [networkIsOpen, setNetworkIsOpen] = useState(false)
    const [networkInfo, setNetworkInfo] = useState(false)
    const [links, setLinks] = useState({})
    const [searchIsOpen, setSearchIsOpen] = useState(false)
    const [fadeContainer, setFadeContainer] = useState(false)
    let navigate = useNavigate()

    const { unityProvider, loadingProgression, isLoaded, sendMessage, addEventListener, removeEventListener } =
    useUnityContext({
        loaderUrl: "buildUnity/WebGL.loader.js",
        dataUrl: "buildUnity/WebGL.data",
        frameworkUrl: "buildUnity/WebGL.framework.js",
        codeUrl: "buildUnity/WebGL.wasm",
    })

    const getSidebarContent = async () => {
        try{
            //const response = await api.get(`/sidebars/${pageId}?populate=*`)
            const qs = require('qs')
            const query = qs.stringify({
                populate: {
                    Assets: {
                        populate: [
                            "Image",
                            "Video",
                            "PDF",
                            "layer",
                            "solution",
                            "customer",
                            "Subtitles",
                            "Subtitles.VTT",
                        ],
                        sort: ['Order']
                    },
                },
                }, {
                encodeValuesOnly: true,
            })

            const response = await api.get(`/sidebars/${pageId}?${query}`)

            if(response.data){
                setPageInfo(response.data.data.attributes)
            }
        } catch(err) {
            console.log(err)
        }
    }

    const getNetworkContent = async (networkId) => {
        try{
            const qs = require('qs')
            const query = qs.stringify({
                populate: {
                    HeroImage: '*',
                    Assets: {
                        //populate: '*',
                        populate: [
                            "Image",
                            "Video",
                            "PDF",
                            "layer",
                            "solution",
                            "customer",
                            "Subtitles",
                            "Subtitles.VTT",
                        ],
                    },
                },
                }, {
                encodeValuesOnly: true,
            })

            const response = await api.get(`/networks/${networkId}?${query}`)
            if(response.data){
                setNetworkInfo(response.data.data.attributes)
                setLinks(response.data.data.attributes.Assets.data)
                setNetworkIsOpen(true)
            }
        } catch(err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getSidebarContent()
    }, [pageId])

    const handlePopUp = async (isOpen, contentId) => {
        if(!isOpen){
            sendMessage("ExternalInput", "ClosePoI")
            return setPopUpIsOpen(false)
        }
        
        try{
            const qs = require('qs')
            const query = qs.stringify({
            populate: {
                Assets: {
                    //populate: '*',
                    populate: [
                        "Image",
                        "Video",
                        "PDF",
                        "layer",
                        "solution",
                        "customer",
                        "Subtitles",
                        "Subtitles.VTT",
                    ],
                },
            },
            }, {
            encodeValuesOnly: true,
            })

            const response = await api.get(`/contents/${contentId}?${query}`)
            if(response.data){
                setPopUpInfo(response.data.data.attributes)
                setPopUpIsOpen(true)
            }
        } catch(err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getSidebarContent()
        addEventListener("changePage", (pageId) => {
            setPageId(pageId)
        })
        addEventListener("popup", (contentId) => {
            handlePopUp(true, contentId)
        })
        addEventListener("getNetworkContent", (networkId) => {
            getNetworkContent(networkId)
        })
        addEventListener("openURL", (url) => {
            setTimeout(() => {
                setFadeContainer(true)
                setTimeout(() => {
                    localStorage.setItem('backTo', '/')
                    navigate(url)
                }, 600)
            }, 2000)
        })
    }, [])

    useEffect(() => {
        sendMessage("ContentService", "Initialize", process.env.REACT_APP_API_URL)
    }, [isLoaded])

    const handleLinksBox = (isOpenLinksBox, links) => {
        if(!isOpenLinksBox){
            sendMessage("ExternalInput", "ClosePoI")
            return setLinksBoxIsOpen(false)
        }

        setLinksBoxIsOpen(true)
        setLinks(links)
    }

    const handleNetwork = (isOpenLinksBox, links) => {
        if(!isOpenLinksBox){
            sendMessage("ExternalInput", "ClosePoI")
            return setNetworkIsOpen(false)
        }

        setNetworkIsOpen(true)
        setLinks(links)
    }

    const handleSearch = (isSearchOpen) => {
        setSearchIsOpen(isSearchOpen)
    }
    

    return (
        <div className={`h container ${fadeContainer ? 'fade-out' : ''}`}>
            <div className="loading" style={{ display: isLoaded ? "none" : "flex" }}>
                <div className="progress">
                    <span className="progress-bar" style={{width: `${loadingProgression * 100}%`}}></span>
                </div>
            </div>
            <div className='overUnity'>
                <header>
                    <div className='logoBox'>
                        <img className="logo" src="/hpe-logo-black.png" alt="HPE Enterprise logo" />
                    </div>
                    <nav>
                        <button className='allverticals' onClick={() => navigate(`/verticals`)}>See All Industries</button>
                        <button className='searchbtn' onClick={() => handleSearch(true)}>Search</button>
                    </nav>
                </header>
                <div className='intro'>
                    <h1>Explore how HPE modernizes industry</h1>
                    <Content content="HPE helps global industries modernize with strategic solutions for data, security, edge, and cloud" containerClass="content-container" />
                </div>
            </div>
            
            
            <Search searchIsOpen={searchIsOpen} handleSearch={handleSearch} />
            
            <div className="unity">
                <Unity
                    unityProvider={unityProvider}
                    devicePixelRatio={window.devicePixelRatio}
                />
                {linksBoxIsOpen && ( <LinksBox linksBoxIsOpen={linksBoxIsOpen} handleLinksBox={handleLinksBox} links={links} /> )}
                {popUpIsOpen && ( <PopUp popUpInfo={popUpInfo} isOpen={popUpIsOpen} closePopUp={() => handlePopUp(false)} handleLinksBox={handleLinksBox} /> )}
            </div>
            {networkIsOpen && ( <Network networkIsOpen={networkIsOpen} handleNetwork={handleNetwork} links={links} content={networkInfo} /> )}
        </div>
    )
}