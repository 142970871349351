import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './vertical.css'
import api from '../../services/api'
import Content from '../../components/content'
import Header from '../../components/header'

export default function Industry() {
    const { id } = useParams()
    const apiURL = process.env.REACT_APP_API_URL
    const [vertical, setVertical] = useState()

    const getContent = async () => {
        try{
            //const response = await api.get(`/sidebars/${pageId}?populate=*`)
            const qs = require('qs')
            const query = qs.stringify({
                populate: {
                    populate: '*',
                    HeroImage: '*',
                    Icon: '*',
                    Needs: {
                        populate: '*',
                    },
                },
                }, {
                encodeValuesOnly: true,
            })

            const response = await api.get(`/verticals/${id}/?${query}`)

            if(response.data){
                setVertical(response.data.data)
            }
        } catch(err) {
            console.log(err)
        }
    }

    let navigate = useNavigate()
    const handleClick = (needId) => {
        navigate(`/needs/${id}/${needId}`)
    }

    useEffect(() => {
        getContent()
    }, [])

    return (
        <div className='vertical fade-in'>
            <Header />
            <main>
                <section id="left">
                    <div>
                        <h1>{vertical?.attributes.Title}</h1>
                        <Content content={vertical?.attributes.Content} containerClass="content-container" />
                    </div>
                </section>
                <section id="right">
                    <div className="hero">
                        <img src={apiURL + vertical?.attributes?.HeroImage.data?.attributes.url} />
                    </div>
                    <ul className="menu">
                        {vertical?.attributes.Needs.data.map((need, key) => (
                            <li key={key} className={need.attributes.NeedsType.toLowerCase()} onClick={() => handleClick(need.id)}>
                                    <span>{need.attributes.NeedsType}</span>
                                    <h2>{need.attributes.Title}</h2>
                                    <div className="arrowButton"></div>
                            </li>
                        ))}

                    </ul>
                </section>
            </main>
        </div>
    )

}