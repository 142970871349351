import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import './solution.css'
import api from '../../services/api'
import Content from '../../components/content'
import HeroMedia from './heroMedia'
import LinksBoxItem from '../../components/linksBox/linksBoxItem'
import Header from '../../components/header'
import ScrollMore from '../../components/scrollMore'

export default function Solution() {
    const { verticalId, needsId, solutionId } = useParams()
    const apiURL = process.env.REACT_APP_API_URL
    const [solution, setSolution] = useState()
    const [isScrollVisible, setIsScrollVisible] = useState(true)
    const [fadeContainer, setFadeContainer] = useState(true)

    const getContent = async () => {
        try{
            const qs = require('qs')
            const query = qs.stringify({
                populate: {
                    populate: '*',
                    //HeroMedia: '*',
                    HeroMedia: {
                        populate: [
                            "Image",
                            "Video",
                            "PDF",
                            "Subtitles",
                            "Subtitles.VTT",
                        ],
                    },
                    Icon: '*',
                    Assets: {
                        populate: [
                            "Image",
                            "Video",
                            "PDF",
                            "Subtitles",
                            "Subtitles.VTT",
                        ],
                    },
                },
                }, {
                encodeValuesOnly: true,
            })

            const response = await api.get(`/solutions-pages/${solutionId}/?${query}`)
            if(response.data){
                setSolution(response.data.data)
                setFadeContainer(true)
            }
        } catch(err) {
            console.log(err)
        }
    }

    const listenToScroll = () => {
        let heightToHideFrom = 200
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    
        if (winScroll > heightToHideFrom) {  
             isScrollVisible && setIsScrollVisible(false)
        } else {
             setIsScrollVisible(true)
        }  
      }

    useEffect(() => {
        getContent()
    }, [])

    return (
        <div className='pillar solution'>
            <section className={`container ${fadeContainer ? 'fade-in' : 'fade-out'}`}>
                <Header backURL={`/needs/${verticalId}/${needsId}`} />
                <div className="content">
                    <div>
                        <h3 className='title'>{solution?.attributes.Title}</h3>
                        <div className="hero mobile">
                            {solution?.attributes.HeroMedia.data && <HeroMedia 
                                apiURL={apiURL}
                                media={solution?.attributes.HeroMedia.data}
                            />}
                        </div>
                        <Content content={solution?.attributes.Content} containerClass="content-container" />
                    </div>
                    <div className="hero d">
                        {solution?.attributes.HeroMedia.data && <HeroMedia 
                            apiURL={apiURL}
                            media={solution?.attributes.HeroMedia.data}
                        />}
                    </div>
                </div>
            </section>
            <section className='assets'>
                {solution?.attributes.Assets.data.map((asset) => {
                    const link = asset.attributes
                    const imageURL = link.Image.data ? apiURL + link.Image.data.attributes.url : ''
                    const videoURL = link.Video.data ? apiURL + link.Video.data.attributes.url : ''
                    const pdfURL = link.PDF.data ? apiURL + link.PDF.data.attributes.url : ''
                    const QRCodeURL = link.QRCodeURL ? link.QRCodeURL : ''
                    const mediaType = link.MediaType ? link.MediaType : ''

                    return  <LinksBoxItem 
                                key={asset.id.toString()}
                                url={link.URL}
                                image={imageURL}
                                category={link.Category}
                                title={link.Header}
                                subheader={link.Subheader}
                                video={videoURL}
                                subtitles={link.Subtitles}
                                pdf={pdfURL}
                                QRCodeURL={QRCodeURL}
                                mediaType={mediaType}
                            />
                })}
            </section>
            
            <ScrollMore />
        </div>       
    )

}