import React from 'react'

import Content from '../content'
import './popup.css'

export default function PopUp({popUpInfo, isOpen, closePopUp, handleLinksBox}) {
    return (
        isOpen && (
            <div className="popup" onClick={closePopUp}>
                <div className="popup__inner" onClick={e => e.stopPropagation() }>
                    <button className="popup__close" onClick={closePopUp}>X</button>
                    <span className="popup__category">{popUpInfo.Category}</span>
                    <h1>{popUpInfo.Location}</h1>
                    <div className="popup__inner-content">
                        {popUpInfo.Requirements && (
                            <div className='popup__requirements'>
                                <h2>Requirements</h2>
                                <Content content={popUpInfo.Requirements} containerClass="popup__requirements-content" />
                            </div>
                        )}
                        
                        {popUpInfo.Outcome && (
                            <div className='popup__outcome'>
                                <h2>Outcome</h2>
                                <Content content={popUpInfo.Outcome} containerClass="popup__outcome-content" />
                            </div>
                        )}
                        
                        {popUpInfo.Highlight && ( 
                            <Content content={popUpInfo.Highlight} containerClass="popup__highlight" /> 
                        )}

                        {popUpInfo.Solution && (
                            <div className='popup__solution'>
                                <h2>Solution</h2>
                                <Content content={popUpInfo.Solution} containerClass="popup__solution-content" />
                            </div>
                        )}

                        {popUpInfo.PlainText && (
                            <div className='popup__plaintext'>
                                <Content content={popUpInfo.PlainText} containerClass="popup__plaintext-content" />
                            </div>
                        )}
                    </div>
                    {popUpInfo.Assets.data.length!==0 && (
                        <div className='popup__footer'>
                            <button className='discoverMore' onClick={() => handleLinksBox(true, popUpInfo.Assets.data)}>Discover more</button>
                        </div>
                    )}
                </div>
            </div>
        ) 
    )
    }