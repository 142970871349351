import React, { useState, useEffect } from 'react'
import './scrollMore.css'

export default function scrollMore() {
    const [isScrollVisible, setIsScrollVisible] = useState(true)

    const listenToScroll = () => {
        let heightToHideFrom = 200
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    
        if (winScroll > heightToHideFrom) {  
             isScrollVisible && setIsScrollVisible(false)
        } else {
             setIsScrollVisible(true)
        }  
    }

    const handleClick = () => {
        const scrollTo = window.innerHeight - 150
        window.scrollTo({
            top: scrollTo,
            behavior: 'smooth',
          })
    }

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll)
        return () => 
            window.removeEventListener("scroll", listenToScroll)
    }, [])

    return (
        <div className={`scrolldown${isScrollVisible ? '' : ' hide'}`} onClick={handleClick}>
            <span className="readmore-arrow">Scroll for more</span>
        </div>
    )
}

