import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './verticals.css'
import api from '../../services/api'
import Header from '../../components/header'

export default function Industries() {
    const apiURL = process.env.REACT_APP_API_URL
    const [verticals, setVerticals] = useState()

    const getContent = async () => {
        try{
            //const response = await api.get(`/sidebars/${pageId}?populate=*`)
            const qs = require('qs')
            const query = qs.stringify({
                populate: {
                    populate: '*',
                    HeroImage: '*',
                    Icon: '*',
                },
                sort: ['id'],
                }, {
                encodeValuesOnly: true,
            })

            const response = await api.get(`/verticals/?${query}`)
            if(response.data){
                setVerticals(response.data.data)
            }
        } catch(err) {
            console.log(err)
        }
    }

    let navigate = useNavigate()
    const handleClick = (id) => {
        localStorage.setItem('backTo', '/verticals')
        navigate(`/vertical/${id}`)
    }
    const handleClose = () => {
        navigate(`/`)
    }

    useEffect(() => {
        getContent()
    }, [])

    return (
        <div className='verticals'>
            <Header logo={true} />
            <ul className='verticalsList'>
                {verticals?.map((item, key) => (
                    <li key={key} onClick={() => handleClick(item.id)}>
                        <h3>{item.attributes.Name}</h3>
                        <div className={`gradient ${item.attributes.Name.toLowerCase().replace(" ", "")}`}>
                            <div><img src={apiURL + item.attributes.Icon.data.attributes.url} /></div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
        
    )

}