import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import './origin.css'

export default function Origin({ children }){
    const [searchParams] = useSearchParams()
    const [limit, setLimit] = useState(false)

    useEffect(() => {
        const origin = searchParams.get("origin") ? searchParams.get("origin") : sessionStorage.getItem('origin')
        
        console.log("origin", origin)

        if(origin){
            sessionStorage.setItem("origin", origin)
            setLimit("cic")
        } else {
            setLimit(false)
        }


        /*
        if (!sessionStorage.getItem('origin')) {
            if(searchParams.get("origin") == "cic"){
                sessionStorage.setItem("origin", "cic")
                setLimit("cic")
            }
        } else {
            setLimit(false)
        }
        */
    }, [])
  
    if(limit == "cic"){
        return (
            <div className='limit'>
                {children}
            </div>
        )
    } else {
        return children
    }
}
