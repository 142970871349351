import React, { useEffect, useRef } from 'react'
import './network.css'
import LinksBoxItem from '../linksBox/linksBoxItem'
import Content from '../content'
import defaultHero from "./default-hero.jpg";

export default function Network({networkIsOpen, handleNetwork, links, content}) {
    const networkRef = useRef(null)
    const apiURL = process.env.REACT_APP_API_URL
    const heroImage = content.HeroImage.data?.attributes.formats.large.url

    function useOnClickOutside(ref, handler) {
        useEffect(
          () => {
            const listener = (event) => {
              if (!ref.current || ref.current.contains(event.target)) {
                return;
              }
              handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
              document.removeEventListener("mousedown", listener);
              document.removeEventListener("touchstart", listener);
            };
          },
          [ref, handler]
        );
      }

    useOnClickOutside(networkRef, () => handleNetwork(false))

    return (
        networkIsOpen && (
            <div className="network">
                <button className="network__close" onClick={() => handleNetwork(false)}>X</button>
                <div ref={networkRef} className="network__container">
                    <div 
                        className='network__hero' 
                        style={{ 
                            backgroundImage: `url(${heroImage ? apiURL + heroImage : defaultHero})`
                        }}
                    >
                        <div className='content'>
                            <h1>{content.Title}</h1>
                            <Content content={content.Content} containerClass="content-container" />
                        </div>
                    </div>
                    
                    {links.map((link) => {
                        const imageURL = link.attributes.Image.data ? apiURL + link.attributes.Image.data.attributes.url : ''
                        const videoURL = link.attributes.Video.data ? apiURL + link.attributes.Video.data.attributes.url : ''
                        const pdfURL = link.attributes.PDF.data ? apiURL + link.attributes.PDF.data.attributes.url : ''

                        return  <LinksBoxItem 
                                    key={link.id}
                                    url={link.attributes.URL}
                                    image={imageURL}
                                    type={link.attributes.Type}
                                    title={link.attributes.Header}
                                    subheader={link.attributes.Subheader}
                                    video={videoURL}
                                    subtitles={link.attributes.Subtitles}
                                    pdf={pdfURL}
                                />
                    })}
                    
                </div>
            </div>
        )
    )
}