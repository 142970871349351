import React, {useState, useEffect} from 'react'
import QRCode from 'react-qr-code'
import VideoPlayer from '../videoPlayer'
import Pdf from '../pdf'
import CTA from './cta'
import './linksBoxItem.css'

export default function LinksBoxItem({url, image, category, title, subheader, video, subtitles, pdf, QRCodeURL, mediaType}) {
    const [videoIsOpen, setVideoIsOpen] = useState(false)
    const [pdfIsOpen, setpdfIsOpen] = useState(false)

    const handleLink = (url, video, pdf) => {
        if(video){
            setVideoIsOpen(true)
        } else if(pdf){
            setpdfIsOpen(true)
        } else {
            window.open(url)
        }
    }

    const handleVideoPlayer = (isOpen) => {
        setVideoIsOpen(isOpen)
    }

    const handlePdfViewer = (isOpen) => {
        setpdfIsOpen(isOpen)
    }

    useEffect(() => {
        const handleEscapeKey = (e) => {
            if (e.key === 'Escape') {
                handleVideoPlayer(false)
                setpdfIsOpen(false)
            }
        }
        document.addEventListener('keydown', handleEscapeKey)
        return () => document.removeEventListener('keydown', handleEscapeKey)
    }, [])

    return (
        <>
            <div className="linksBoxItem" onClick={() => handleLink(url, video, pdf)}>
                <div className="header">
                    <img src={image} alt={title} />
                    {QRCodeURL && (
                        <div className="qrcode">
                            <QRCode 
                                size={100}
                                value={QRCodeURL}
                            />
                        </div>
                    )}
                    <span className='category'>{category}</span>
                </div>
                <div className='body'>
                    {title && (
                        <h3>{title}</h3>
                    )}

                    {subheader && (
                        <h4>{subheader}</h4>
                    )}
                    <CTA mediaType={mediaType} />
                </div>
            </div>
            {videoIsOpen && (
                <VideoPlayer 
                    url={video} 
                    QRCodeURL={QRCodeURL}
                    title={title}
                    subheader={subheader}
                    subtitles={subtitles}
                    handleVideoPlayer={handleVideoPlayer}
                />
            )}
            {pdfIsOpen && (
                <Pdf 
                    url={pdf} 
                    QRCodeURL={QRCodeURL}
                    title={title}
                    handlePdfViewer={handlePdfViewer}
                />
            )}
        </>
    )
}