import React, { useState, useEffect, useRef } from 'react'
import QRCode from "react-qr-code"
import api from '../../services/api'
import './video.css'

export default function VideoPlayer({url, QRCodeURL, title, subheader, subtitles, handleVideoPlayer}) {
    const apiURL = process.env.REACT_APP_API_URL
    const [language, setLanguage] = useState('en')
    const videoRef = useRef(null)

    useEffect(async () => {
        if(sessionStorage.getItem("language")){
            setLanguage(sessionStorage.getItem("language"))
        } else {
            try{
                const response = await api.get(`/setting`)
                if(response.data){
                    const language = response.data.data.attributes.Language
                    setLanguage(language)
                    sessionStorage.setItem("language", language)
                }
            } catch(err) {
                console.log(err)
            }
        }

        
    }, [])

    const closePlayer = () => {
        const video = videoRef.current
        const tracks = [ ...video.textTracks ]

        if(Array.isArray(tracks) && tracks.length) {
            const active = tracks.find( track => track.mode === "showing" )
            if (active) sessionStorage.setItem("language", active.language)
        }

        handleVideoPlayer(false)
    }

    function useOnClickOutside(ref, handler) {
        useEffect(
          () => {
            const listener = (event) => {
              if (!ref.current || ref.current.contains(event.target)) {
                return
              }
              handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
              document.removeEventListener("mousedown", listener);
              document.removeEventListener("touchstart", listener);
            }
          },
          [ref, handler]
        )
      }

    useOnClickOutside(videoRef, closePlayer)

    return (
        <div className="videoPlayer">
            <header className='videoPlayer__header'>
                <button className="videoPlayer__back" onClick={() => closePlayer(false)}>Back</button>
                <div className='videoPlayer__titleBox'>
                    {title && (
                        <h3 className='videoPlayer__header'>{title}</h3>
                    )}
                    {QRCodeURL && (
                        <div className='qrcode'>
                            <QRCode 
                                size={76}
                                value={QRCodeURL}
                            />
                        </div>
                    )}
                </div>
            </header>
            <div className='videoPlayer__container'>
                <div className='gradientContainer'>
                    <video ref={videoRef} controls autoPlay controlsList="nodownload noplaybackrate" crossOrigin="anonymous">
                        <source src={url} type="video/mp4" />
                        {subtitles && subtitles.map((sub, index) => (
                            <track 
                                className='subs'
                                key={index}
                                label={sub.Label} 
                                kind="captions" 
                                srcLang={sub.Language} 
                                src={apiURL + sub.VTT.data?.attributes.url} 
                                default={language === sub.Language}
                            ></track>
                        ))}
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    )
}