import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Search from '../../components/search'
import './header.css'

export default function Header({logo, backURL}) {
    const [searchIsOpen, setSearchIsOpen] = useState(false)
    let navigate = useNavigate()

    const handleSearch = (isSearchOpen) => {
        setSearchIsOpen(isSearchOpen)
    }

    const handleBackButton = () => {
        if(backURL) navigate(backURL)
        else if(localStorage.getItem('backTo')) navigate(localStorage.getItem('backTo'))
        else if(navigate(-1)) navigate(-1)
        else navigate('/')
    }

    return (
        <header className='header'>
            {
                logo &&
                    <img className="logo" src="/hpe-logo-black.png" alt="HPE Enterprise logo" />
            }
            {
                !logo && (
                    <div className="back">
                        <button onClick={handleBackButton}>Back</button>
                    </div>
                )
            }
            <nav>
                <button className='cityscape' onClick={() => navigate(`/`)}>Back to Cityscape</button>
                <button className='searchbtn' onClick={() => handleSearch(true)}>Search</button>
            </nav>
            <Search searchIsOpen={searchIsOpen} handleSearch={handleSearch} />
        </header>
        
    )
}

